import React from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"

import SingleTeamMember from "./../../components/single-team-member"

import "../../styles/teams.scss"

import players from "./../../data/players"

export default function TeamPage() {
  const currentPayer = "/teams/pratt"
  const currentCharacter = players.find(el => el.url === currentPayer) || false

  const pageTitle = "Team " + currentCharacter.text

  return (
    <Layout header={false}>
      <div id="team">
        <SEO title={pageTitle} />
        {currentCharacter && (
          <SingleTeamMember currentCharacter={currentCharacter} />
        )}
      </div>
    </Layout>
  )
}
